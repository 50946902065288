nav {
  @apply xl:fixed xl:top-0 xl:left-0 xl:w-full xl:z-20 xl:h-screen;
}

.navbar-logo {
  /* adjust the logo styles if needed */
  max-height: 40px;
  /* Full height */
  margin-left: 1rem;
  margin-right: 1rem;
}

.mobile-menu {
  display: flex !important;
  position: absolute;
  top: 80px;
  right: 0px;
  background: #fff;
  z-index: 11;
  width: 100%;
  border-top: 1px solid #ccc;
  left: 0;
  min-height: calc(100vh - 80px);
  transition: all 0.3s;
}

/* Existing button styles */

/* New styles for Settings link/button */
.btn-settings-navbar {
  background-color: #4b6587;
  /* Example color */
  /* Rest of the button styles similar to .btn-penomo-navbar */
}

/* Add any additional styles as needed */

.btn-penomo-navbar {
  background-color: #00cc9c;
  /* Penomo color */
  color: #333;
  /* Text color set to black */
  border: none;
  /* Removes any default border */
  padding: 10px 20px;
  /* Padding for size */
  border-radius: 5px;
  /* Rounded corners */
  font-weight: bold;
  /* Bold font */
  cursor: pointer;
  /* Cursor indicates clickable button */
  outline: none;
  /* Removes outline on focus for some browsers */
  transition: background-color 0.3s;
  /* Smooth transition for hover effect */
  /* margin-top: 0.5rem; */
  margin: auto;
  margin-left: 0.5rem;
  text-decoration: none;
  /* Removes underline from links if it's an <a> element */
  display: block;
  /* Makes the link behave like a block element */
  justify-content: center;
  align-items: center;
}

.btn-penomo-navbar:active {
  background-color: #00cc9c;
  /* This will make the button green when clicked */
  color: white;
  /* Optional: change text color to white when active/selected */
  /* Any other styles you want to apply when the button is clicked */
}

.btn-penomo-navbar:hover {
  background-color: #00cc9c;
  /* This will make the button green when clicked */
  color: white;
  /* Optional: change text color to white when active/selected */
  /* Any other styles you want to apply when the button is clicked */
}

.btn-secondary-navbar {
  display: flex;
  align-items: center;
  background-color: #fff;
  /* Penomo color */
  color: #333;
  /* Text color set to black */
  border: none;
  /* Removes any default border */
  padding: 10px 15px;
  /* Padding for size */
  border-radius: 5px;
  /* Rounded corners */
  font-weight: 400;
  /* Bold font */
  cursor: pointer;
  /* Cursor indicates clickable button */
  outline: none;
  /* Removes outline on focus for some browsers */
  transition: background-color 0.3s;
  /* Smooth transition for hover effect */
  /*margin-top: 0.5rem; */
  margin: auto;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  text-decoration: none;
  /* Removes underline from links if it's an <a> element */
  justify-content: flex-start;
}

.btn-secondary-navbar:active {
  background-color: #00cc9c;
  /* This will make the button green when clicked */
  color: white;
  /* Optional: change text color to white when active/selected */
  font-weight: 500;
}

.btn-secondary-navbar:hover {
  background-color: #00cc9c;
  /* This will make the button green when clicked */
  color: white;
  /* Optional: change text color to white when active/selected */
  /* Any other styles you want to apply when the button is clicked */
}

.btn-tertiary-navbar {
  background-color: #d9edea;
  /* Penomo color */
  color: #333;
  /* Text color set to black */
  border: none;
  /* Removes any default border */
  padding: 10px 20px;
  /* Padding for size */
  border-radius: 5px;
  /* Rounded corners */
  font-weight: bold;
  /* Bold font */
  cursor: pointer;
  /* Cursor indicates clickable button */
  outline: none;
  /* Removes outline on focus for some browsers */
  transition: background-color 0.3s;
  /* Smooth transition for hover effect */
  margin-top: 0.5rem;
  margin: auto;
  margin-left: 0.5rem;
  text-decoration: none;
  /* Removes underline from links if it's an <a> element */
  display: block;
  /* Makes the link behave like a block element */
  justify-content: center;
  align-items: center;
}

.btn-tertiary-navbar:active {
  background-color: #00cc9c;
  /* This will make the button green when clicked */
  color: white;
  /* Optional: change text color to white when active/selected */
  /* Any other styles you want to apply when the button is clicked */
}

.btn-tertiary-navbar:hover {
  background-color: #00cc9c;
  /* This will make the button green when clicked */
  color: white;
  /* Optional: change text color to white when active/selected */
  /* Any other styles you want to apply when the button is clicked */
}