@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body,
html {
  height: 100%;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 17px;
  font-style: normal;
  font-weight: 400;
  overflow-x: hidden;
  background-color: rgb(247, 245, 242);
  -webkit-font-smoothing: antialiased;
  -webkit-text-strokewidth: 0.2px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@media (min-width: 321px) and (max-width: 767px) {
  .notification-card1 {
    /* Apply styles for the notification cards within the specified range */
  }

  .notification-card1:nth-child(2) {
    top: -90px; /* Adjust this value as needed */
  }

  .notification-card1:nth-child(3) {
    top: -200px; /* Adjust this value as needed */
  }
}

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
